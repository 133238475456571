// SUPER IMPORTANT FOR ICON COLORS: https://github.com/vaso2/nuxt-fontawesome/issues/15

.icon {
  --fa-primary-color: #{$gray-600};
  --fa-secondary-color: #{$gray-500};
  --fa-primary-opacity: 0.9;
  --fa-secondary-opacity: 0.3;
}

.icon-primary {
  --fa-primary-color: #{$primary-400};
  --fa-secondary-color: #{$primary-300};
  --fa-primary-opacity: 1;
}

.modal-close-icon {
  @extend .icon;
  cursor: pointer;
  transition: all 0.1s linear;

  &:hover {
    background-color: $gray-100;
    --fa-primary-color: #{$danger};
    --fa-secondary-color: #{$danger};
  }
}
