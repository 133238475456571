.btn {
  border-radius: $radius;
}

// Primary.
.btn-primary {
  color: white;
  background-color: $primary;
}

.btn-outline-primary {
  color: $primary;
  border: 1px solid $primary;

  &:hover {
    color: white;
    background-color: $primary;
  }
}

.btn-primary-faded {
  color: $primary-400;
  background-color: $primary-100;
  border-color: $primary-400;

  &:hover {
    color: white;
    background-color: $primary-400;
  }
}

// Warning.
.btn-warning {
  color: white;
  background-color: $warning;
}

.btn-warning-faded {
  color: $warning;
  background-color: $warning-100;
  border-color: $warning;

  &:hover {
    color: white;
    background-color: $warning-500;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem transparentize($color: $warning-500, $amount: 0.7);
  }
}
