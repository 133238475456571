/* Navbar */
.navbar {
  background-color: white;
}

.navbar-item {
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.navbar-item:hover {
  background-color: $gray-100;
}

.menu-selected {
  background-color: $gray-100;
}

/* Tabs. */
.tab-container {
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 4vh;
}

.tab-title {
  font-weight: 900;
}

/* Modal. */
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(9, 30, 66, 0.54);
  z-index: 9999;
  animation: fadeIn 0.2s cubic-bezier(0.165, 0.4, 0.6, 1) forwards;
}

.custom-modal-body {
  position: absolute;
  opacity: 0;
  min-width: 600px;
  max-width: 1000px;
  top: 10vh;
  padding: 2.5rem;
  background-color: white;
  border-radius: $radius;
  animation: fadeInUp 0.2s cubic-bezier(0.2, 0.4, 0.5, 1) forwards;
  animation-delay: 0.2s;
  width: 1200px;
}

/* Stepper. */
.step {
  padding: 0.5rem;
  border-radius: $radius;
  transition: background-color 0.1s linear;

  &-number {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    color: $gray-300;
    justify-content: center;
    background-color: $gray-100;
  }

  &-label {
    margin-left: 0.75rem;
    color: $gray-300;
  }

  &.active {
    cursor: pointer;

    &:hover {
      background-color: $gray-100;
    }

    > .step-number {
      background-color: $primary-100;
      color: $primary-300;
    }

    > .step-label {
      font-weight: bold;
      color: $primary-300;
    }
  }
}

// Badge.
.badge {
  display: inline-block;
  white-space: nowrap;
  border-radius: $radius;
}

.badge-primary {
  background-color: $primary-400;
}

.badge-success {
  background-color: $success;
}

// Avatar.
.avatar {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: $radius * 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-200;
}

// Toast.
.custom-toast {
  padding: 1rem;
  color: white;
  border-radius: $radius;

  &.primary {
    background-color: $primary-200;
  }

  &.danger {
    background-color: $danger-500;
  }

  .toast-close-icon {
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &.top-right {
    top: 5vh;
    right: 5vw;
  }

  &.top-left {
    top: 5vh;
    left: 5vw;
  }

  &.top-center {
    top: 5vh;
    left: "50%";
  }

  &.bottom-right {
    bottom: 5vh;
    right: 5vw;
  }

  &.bottom-left {
    bottom: 5vh;
    left: 5vw;
  }

  &.bottom-center {
    bottom: 5vh;
    left: "50%";
  }
}

// Form CSS.

// Input.
.custom-input {
  background: $gray-100;
  padding: 8px 6px;
  border: 2px solid;
  border-radius: $radius;
  border-color: $gray-300;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:hover:not(:focus) {
    background-color: $gray-200;
  }

  &:focus {
    border-color: $primary-200;
    box-shadow: none !important;
  }
}

.input-hint {
  color: $gray-600;
}

// Avatar Input.
.avatar-input {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  background-color: $gray-200;
}
