@import "../../styles/master";
@import "../../styles/animations.scss";
@import "../../styles/general.scss";
@import "../../styles/icons.scss";
@import "../../styles/buttons.scss";
@import "../../styles/doctors.scss";

html,
body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: "Inter", sans-serif;
}

// Redesign CSS.
.rounded {
  border-radius: $radius !important;
}

.app-container {
  width: 100vw;
  height: 100vh;
}

// End Redesign CSS.

.col-fix-right {
  padding-right: 0 !important;
}

/* Menu CSS */
.menu-item {
  padding: 0.75em 0;
  cursor: pointer;
}

.menu-icon {
  font-size: 1.5rem;
  /* text-align: center; */
  margin-top: auto;
  margin-bottom: auto;
}

.menu-text {
  text-align: left;
}

.menu-nav {
  height: 100vh;
}

.m-fix {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.menu-elevation {
  margin-right: 10px;
}

/* Table CSS */
.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.table-icon {
  font-size: 16px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Doctors CSS */
.fab {
  position: fixed;
  width: 56px;
  height: 56px;
  right: 25px;
  bottom: 25px;
  background-color: #1a22ff;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.fab i {
  color: white;
  font-size: 2rem;
  line-height: 56px;
}

.doc-container {
  margin-right: 5vw;
  margin-left: 2.5vw;
}

.table-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

/* Modal CSS */

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  padding-top: 7.5vh; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal.show {
  display: block;
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  border-radius: 1rem;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
}

.modal-small {
  width: 40% !important;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Forms CSS */

.custom-select {
  border-radius: 1rem;
}

.custom-select-light-green {
  color: #6ab597;
  background-color: #e0faf1;
  border-color: transparent;
}

.custom-select-light-green:focus {
  color: #6ab597;
  background-color: #e0faf1;
  border-color: transparent;
  box-shadow: 0 0 0 0.2rem rgba(106, 181, 151, 0.25);
}

.custom-select-purple {
  color: #8571c6;
  background-color: #efeafd;
  border-color: transparent;
}

.custom-select-purple:focus {
  color: #8571c6;
  background-color: #efeafd;
  border-color: transparent;
  box-shadow: 0 0 0 0.2rem rgba(133, 113, 198, 0.25);
}

/* Table CSS */

.custom-badge-light-green {
  color: #6ab597;
  background-color: #e0faf1;
  border-radius: 1rem;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
}

.custom-badge-purple {
  color: #8571c6;
  background-color: #efeafd;
  border-radius: 1rem;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
}

/* Filters CSS */

.filter-select {
  border: none;
  font-weight: bold;
}

.dropdown-item {
  cursor: pointer;
}

/* React-Toggle CSS */
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  /* background-color: #19AB27; */
  background-color: #1a22ff;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  /* background-color: #128D15; */
  background-color: #1a22ff;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #1a22ff;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

/* Settings CSS */
.set-icon {
  font-size: larger;
  cursor: pointer;
}

/* Date picker for Appointment Filters CSS */
.InputFromTo
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.InputFromTo .DayPickerInput-Overlay {
  width: 550px;
}
.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}

.rotate-90 {
  transform: rotate(90deg);
  transition: all 0.2s linear;
}

.no-rotation {
  transform: rotate(0deg);
  transition: all 0.2s linear;
}

/* Appointments CSS */
.filter-reset {
  cursor: pointer;
  padding: 2px;
}

.filter-reset:hover {
  background-color: black;
  color: white;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

/* Supplier Modal CSS */
.round-reset {
  cursor: pointer;
  padding: 2px;
}

.round-reset:hover {
  background-color: black;
  color: white;
}

/* Metrics CSS */
.card-icon {
  padding: 10px;
  border-radius: 50%;
  font-size: xx-large;
}

.card-icon-light-green {
  color: #6ab597;
  background-color: #e0faf1;
}

.card-icon-purple {
  color: #8571c6;
  background-color: #efeafd;
}

.select-style {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  min-height: 38px;
}

/* Fix for dropdown inside accordion not showing. */
.accordion .card {
  overflow: inherit;
}

.fix-overflow {
  overflow: inherit;
}

.custom-acc-header {
  background-color: #f6f8fa;
  cursor: pointer;
  padding: 0 !important;
}

.custom-acc-header h6 {
  font-weight: bold;
  padding: 1rem;
}

/* Stepper CSS */
.step {
  border-radius: 0.25rem;
  padding: 0.75rem;
}

.step:hover {
  background-color: rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.step-number {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: 0.5em 0;
  margin: 0.25rem;
  line-height: 1em;
  color: #fff;
  background-color: #b3d7ff;
  border-radius: 1em;
  color: #007bff;
}

.step-disabled {
  background-color: #d6d9db;
  color: #6c757d;
}

.step-disabled + .step-label {
  color: #6c757d;
}

.step-label {
  font-weight: bold;
  color: #007bff;
}

/* .step-label:hover {
  color: black;
} */

.step-line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.12);
}

/* Buttons */

/* Button Primary */
.btn-primary {
  background-color: #e6f2ff;
  color: #007bff;
  border: none;
  font-weight: bold;
  border-radius: 0.75rem;
  padding: 0.625em 1em;
}

.btn-primary:hover {
  background-color: #b3d7ff;
  color: #007bff;
  border: none;
}

.btn-primary:focus {
  background-color: #b3d7ff;
  color: #007bff;
  border: none;
}

/* Button Danger */
.btn-danger {
  background-color: #f7d4d7;
  color: #dc3545;
  border: none;
  font-weight: bold;
  border-radius: 0.75rem;
  padding: 0.625em 1em;
}

.btn-danger:hover {
  background-color: #f3bec3;
  color: #dc3545;
  border: none;
}

.btn-danger:focus {
  background-color: #f3bec3;
  color: #dc3545;
  border: none;
}

/* Button Warning */
.btn-warning {
  background-color: #fff9e6;
  color: #ffc107;
  border: none;
  font-weight: bold;
  border-radius: 0.75rem;
  padding: 0.625em 1em;
}

.btn-warning:hover {
  background-color: #ffedb3;
  color: #ffc107;
  border: none;
}

.btn-warning:focus {
  background-color: #ffedb3;
  color: #ffc107;
  border: none;
}

/* Button Secondary */
.btn-secondary {
  background-color: #f1f2f3;
  color: #6c757d;
  border: none;
  font-weight: bold;
  border-radius: 0.75rem;
  padding: 0.625em 1em;
}

.btn-secondary:hover {
  background-color: #d6d9dc;
  color: #6c757d;
  border: none;
}

.btn-secondary:focus {
  background-color: #d6d9dc;
  color: #6c757d;
  border: none;
}

.table-card {
  border: none !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: #b3d7ff;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #b3d7ff;
}

.react-toggle--checked .react-toggle-thumb {
  border-color: #007bff;
}

.react-toggle-track {
  background-color: #d6d9db;
}

/* 

CSS for scrollbar.

/* width *
::-webkit-scrollbar {
  width: 10px;
}

/* Track *
::-webkit-scrollbar-track {
  background: white; 
}
 
/* Handle *
::-webkit-scrollbar-thumb {
  border-radius: .75rem;
  background: #cce4ff;
}

/* Handle on hover *
::-webkit-scrollbar-thumb:hover {
  background: #007bff; 
}


*/

.swal2-confirm {
  background-color: #e6f2ff !important;
  color: #007bff !important;
  border-radius: 0.75rem !important;
}

.swal2-confirm:hover {
  background-color: #b3d7ff !important;
}

.swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-cancel {
  background-color: #f7d4d7 !important;
  color: #dc3545 !important;
  border-radius: 0.75rem !important;
}

.swal2-cancel:hover {
  background-color: #f3bec3 !important;
}

.swal2-cancel:focus {
  box-shadow: none !important;
}

.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
  box-shadow: 0 0 3px #007bff !important;
}

/* Suppliers */
.nav-tabs {
  border-bottom: none;
}

.nav-link {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;

  color: black;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: transparent;
  border-bottom: 1px solid #b3d7ff;

  color: black;
  font-weight: bold;
}

caption {
  padding-left: 0.75rem;
  font-weight: bold;
  font-size: 1.5rem;
  caption-side: top;
  color: #000;
}

.login {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Loading spinner CSS */
.loader-container {
  min-height: 100vh;
  display: grid;
  place-items: center;
}

svg.tea {
  --secondary: #33406f;
}

svg.tea #teabag {
  transform-origin: top center;
  transform: rotate(3deg);
  animation: swing 2s infinite;
}

svg.tea #steamL {
  stroke-dasharray: 13;
  stroke-dashoffset: 13;
  animation: steamLarge 2s infinite;
}

svg.tea #steamR {
  stroke-dasharray: 9;
  stroke-dashoffset: 9;
  animation: steamSmall 2s infinite;
}

@-moz-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-webkit-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-o-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-moz-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-webkit-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-o-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-moz-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@-webkit-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@-o-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

.DayPickerInput input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

/* HIDDEN DIV */

.hiddendiv {
  opacity: 0;
}

.tableoverflow {
  overflow-y: auto;
}

.debt-table {
  max-height: 400px;
  overflow-y: auto;
}

.blurred-card {
  filter: blur(10px);
}
