@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    top: 11vh;
  }
  100% {
    opacity: 1;
    top: 10vh;
  }
}
