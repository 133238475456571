/* Primary */
$primary: #2151ec;
$primary-100: #dee5fc;
$primary-200: #a6b9f7;
$primary-300: #6f8ef3;
$primary-400: #4d74f0;
$primary-500: #2151ec;
$primary-600: #1c45c9;
$primary-700: #1739a5;
$primary-800: #102876;
$primary-900: #0c1c53;
/* Secondary */
$secondary: #49b6ff;
$secondary-100: #e4f4ff;
$secondary-200: #b6e2ff;
$secondary-300: #89d0ff;
$secondary-400: #6dc5ff;
$secondary-500: #49b6ff;
$secondary-600: #3e9bd9;
$secondary-700: #337fb3;
$secondary-800: #245b80;
$secondary-900: #1a4059;
/* Gray */
$gray-50: #f8fafc;
$gray-100: #f1f4f9;
$gray-200: #e2e7f0;
$gray-300: #cfd5e1;
$gray-400: #9ea7b8;
$gray-500: #6f788b;
$gray-600: #4c5469;
$gray-700: #363f55;
$gray-800: #1b1f3b;
$gray-900: #11152a;
/* Danger */
$danger: #ea3b3b;
$danger-500: #f76d6d;
$danger-100: #fef0f0;
/* Warning */
$warning: #f0af23;
$warning-500: #f0af23;
$warning-100: #fffbf1;
/* Success */
$success: #27c281;
$success-500: #61e9b0;
$success-100: #effdf7;
/* Misc */
$radius: 0.65rem;
